import React from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import getStripe from "Utils/getStripe";
import { useAuth } from "Utils/AuthContext";

const PricingPage = () => {
  const {
    user,
    isAuthenticated,
    logout,
    isAuthLoading,
    handleLogin,
    userCredit,
    isFetchingCredit,
    userSubscription,
    getAccessTokenSilently,
  } = useAuth();

  const handleCheckout = async (priceId) => {
    if (!isAuthenticated) {
      handleLogin();
      return;
    }
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: priceId,
          quantity: 1,
        },
      ],
      mode: 'subscription',
      successUrl: `https://smart-hscodes.com/`,
      cancelUrl: `https://smart-hscodes.com/`,
      customerEmail: user?.email,
    });
    if (error) {
      console.warn(error.message);
    }
  };

  const handleUpgrade = async (priceId) => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch("https://mtech-api.com/users-smart-hs/upgrade-subscription-tier", {
        method: "POST",
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          priceId: priceId,
          subscriptionId: userSubscription?.data.sub_id,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        window.location.href = data.url;
      } else {
        console.warn("Upgrade failed:", data);
      }
    } catch (error) {
      console.error("Upgrade error:", error);
    }
  };

  const handleDowngrade = async (priceId) => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch("https://mtech-api.com/users-smart-hs/downgrade-subscription-tier", {
        method: "POST",
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          priceId: priceId,
          subscriptionId: userSubscription?.data.sub_id,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        window.location.href = data.url;
      } else {
        console.warn("Downgrade failed:", data);
      }
    } catch (error) {
      console.error("Downgrade error:", error);
    }
  };


  const pricingpage = [
    {
      price_id: "null",
      id: "0",
      pricingIcon: "uim-telegram-alt",
      pricingName: "Trial",
      pricingPrice: "Free",
      pricingsuccessclass: "btn-soft-primary disabled",
      pricingDetails: [
        {
          id: 1,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "Limited HS Code Search"
        },
        {
          id: 1,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "Limited Regulations Search"
        },
        {
          id: 2,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "1 User per Account "
        },
        {
          id: 5,
          pricingInnerClassName: "pricing-item text-decoration-line-through",
          pricingInnerIcon:
            "mdi mdi-close-thick bg-muted-subtle text-muted me-2",
          pricingInnerText: "Full HS Code Details"
        },
        {
          id: 5,
          pricingInnerClassName: "pricing-item text-decoration-line-through",
          pricingInnerIcon:
            "mdi mdi-close-thick bg-muted-subtle text-muted me-2",
          pricingInnerText: "Full Regulations Details"
        },
     ]
    },
    {
      price_id: "price_1QA3GX2LftaZnN8oSdrFyT7e",
      id: "1",
      pricingIcon: "fluent-premium-12-filled",
      pricingName: "Starter",
      pricingPrice: "$36",
      pricingsuccessclass: "btn-primary",
      pricingDetails: [
        {
          id: 1,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "50 Search Credits "
        },
        {
          id: 3,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "Full HS Code Details"
        },
        {
          id: 4,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "Full Regulations Details"
        },

        {
          id: 5,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "1 User per Account "
        },
        {
          id: 6,
          pricingInnerClassName: "pricing-item text-decoration-line-through",
          pricingInnerIcon:
            "mdi mdi-close-thick bg-muted-subtle text-muted me-2",
          pricingInnerText: " Sub Users Not Allowed "
        },
      ]
    },
    {
      price_id: "price_1QFc062LftaZnN8oeIchHZoW",
      id: "2",
      pricingIcon: "mdi-company",
      pricingName: "Premium",
      pricingPrice: "$125",
      pricingsuccessclass: "btn-soft-primary",
      pricingDetails: [
        {
          id: 1,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "500 Search Credits "
        },
        {
          id: 3,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "Full HS Code Details"
        },
        {
          id: 4,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "Full Regulations Details"
        },
        {
          id: 5,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "1 Main Account Holder"
        },

        {
          id: 6,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "Up to 10 Sub users (Share Credits) "
        },
      ]
    },
  ];

  const handleManageSubscription = async (customer_id, is_api = false) => {
    const token = await getAccessTokenSilently();

    const [response] = await Promise.all([
      fetch(
        `https://mtech-api.com/users-smart-hs/create-customer-portal-session${`${is_api ? '-api' : ''}`}?customer_id=${customer_id}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    ]);

    const { url } = await response.json();
    window.location.href = url;
  };

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center">
                <h3>Unlimited Access</h3>
                <p className="text-muted">Unlock our data immediately with our monthly subscription.</p>
              </div>
            </Col>
          </Row>
          <Row>
            {pricingpage.map((plan, key) => (
              <Col lg={4} md={6} className="mt-5 pt-2" key={key}>
                <Card className="pricing-box bg-light">
                  <CardBody className="p-4 px-lg-5">
                    <div className="pricing-icon bg-light rounded-circle icons-md">
                      <Icon icon={plan.pricingIcon} className="text-primary" />
                    </div>
                    <div className="pricing-name text-center mt-4 pt-2">
                      <h4 className="fs-18">{plan.pricingName}</h4>
                    </div>
                    <div className="pricing-price text-center mt-4">
                      <h2 className="fw-semibold">{plan.pricingPrice}<small className="fs-16">/mo</small></h2>
                    </div>
                    <ul className="list-unstyled pricing-details text-muted mt-4">
                      {plan.pricingDetails.map((detail, key) => (
                        <li key={key} className={detail.pricingInnerClassName}>
                          <i className={detail.pricingInnerIcon}></i> {detail.pricingInnerText}
                        </li>
                      ))}
                    </ul>
                    <div className="text-center mt-4 mb-2">
                      {isFetchingCredit ? (
                        <Button className={`btn ${plan.pricingsuccessclass} rounded-pill`} disabled>
                          <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                          Loading...
                        </Button>
                      ) : (
                        plan.price_id === "null" ? (
                          <Button className={`btn ${plan.pricingsuccessclass} rounded-pill`} disabled>Free Plan</Button>
                        ) : !userSubscription?.data ? (
                          <Button onClick={() => handleCheckout(plan.price_id)} className={`btn ${plan.pricingsuccessclass} rounded-pill`}>Subscribe <i className="uil uil-arrow-right"></i></Button>
                        ) : plan.id === userSubscription?.data.tier ? (
                          <Button className={`btn ${plan.pricingsuccessclass} rounded-pill`} onClick={() => handleManageSubscription(userSubscription?.data.customer_id)}>Manage Subscription</Button>
                        ) : plan.id > userSubscription?.data.tier ? (
                          userSubscription.data.next_price_id !== userSubscription.data.curr_price_id ? (
                            <Button className={`btn ${plan.pricingsuccessclass} rounded-pill`} onClick={() => handleManageSubscription(userSubscription?.data.customer_id)}>Manage Subscription</Button>
                          ) : (
                            <Button onClick={() => handleManageSubscription(userSubscription?.data.customer_id)} className={`btn ${plan.pricingsuccessclass} rounded-pill`}>Manage Subscription</Button>
                          )
                        ) : (
                          userSubscription.data.next_price_id !== userSubscription.data.curr_price_id ? (
                            <Button className={`btn ${plan.pricingsuccessclass} rounded-pill`} onClick={() => handleManageSubscription(userSubscription?.data.customer_id)}>Manage Subscription</Button>
                          ) : (
                            <Button onClick={() => handleManageSubscription(userSubscription?.data.customer_id)} className={`btn ${plan.pricingsuccessclass} rounded-pill`}>Manage Subscription</Button>
                          )
                        )
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="section bg-light">
        <Container>
          <Row className="justify-content-center">
            <div className="section-title text-center">
              <h3 className="title mb-4 pb-2">
                Global HS Codes and Regulations.
              </h3>
              <p className="para-desc text-muted mx-auto">
                Get an instant match using our proprietary database and matching algorithm.
              </p>
              <div className="mt-4">
                <Link to="/contact" className="btn btn-primary btn-hover mt-2">
                  <i className="uil uil-phone me-1"></i> Contact
                </Link>

              </div>
            </div>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default PricingPage;
