import { getConfig } from "config";

async function LogRequest(url, responseTime, queries, options = {}) {
    const logUrl = "https://mtech-api.com/web-app/log-smart-hs";

    let isEncrypted = getConfig().encrypt_response;

    const body = {
        url,
        method: "GET",
        responseTime,
        queries,
        user_email: options.userEmail || (isEncrypted ? "unregistered@smarths.com" : "no.reply.moaah@gmail.com"),
        user_sub: options.userSub || "free",
    };

    if (options.userEmail) {
        isEncrypted = false;
    }

    const headers = {
        ...options.headers,
        "Content-Type": "application/json",
        "Accept": isEncrypted ? "application/text" : "application/json",
        "x-user-email": options.userEmail || body.user_email,
        "x-user-sub": body.user_sub,
    };

    const response = await fetch(logUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
    });

    await response.text();
}

export default LogRequest;
