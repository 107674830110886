import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Send page view when the route changes
    window.gtag('config', 'G-0GLY3FY8Y5', {
      page_path: location.pathname,
    });
  }, [location]);
};

export default useGoogleAnalytics;
