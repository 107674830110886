import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Nav,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Input,
  NavItem,
  CardBody
} from "reactstrap";

import classnames from "classnames";

//Images Import
import { useAuth } from "Utils/AuthContext";
import DeleteButton from "./DeleteButton";
import SubUserManagement from "./SubUserManagement";

const RightSideContent = () => {
  const {
    user,
    logout,
    userCredit,
    userCreditUsed,
    userSubscription,
    getAccessTokenSilently,
    isSubUser,
    userInfo,
  } = useAuth();

  useEffect(() => {
    console.log("userInfo", userInfo);
  }, [userInfo]);

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(userSubscription?.api?.apiData.token || '');
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleInvoice = async (invoice_id) => {
    const token = await getAccessTokenSilently();

    const [response] = await Promise.all([
      fetch(
        `https://mtech-api.com/users-smart-hs/invoices/current?invoice_id=${invoice_id}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    ]);

    const { url } = await response.json();
    window.location.href = url;
  };

  const handleManageSubscription = async (customer_id, is_api = false) => {
    const token = await getAccessTokenSilently();

    const [response] = await Promise.all([
      fetch(
        `https://mtech-api.com/users-smart-hs/create-customer-portal-session${`${is_api ? '-api' : ''}`}?customer_id=${customer_id}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    ]);

    const { url } = await response.json();
    window.location.href = url;
  };

  const cancelSubscription = async (subs_id) => {
    const token = await getAccessTokenSilently();

    const [response] = await Promise.all([
      fetch(
        `https://mtech-api.com/users-smart-hs/cancel-subscription?subs_id=${subs_id}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    ]);

    const { url } = await response.json();
    window.location.href = url;
  };

  const [activeTab, setActiveTab] = useState("1");

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <React.Fragment>
      <Col lg={7}>
        <Card className="profile-content-page mt-4 mt-lg-0">
          <Nav
            className="profile-content-nav nav-pills border-bottom mb-4"
            id="pills-tab"
            role="tablist"
          >
            <NavItem role="presentation">
              <NavLink
                to="#"
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  tabChange("1");
                }}
                type="button"
              >
                My Account
              </NavLink>
            </NavItem>
            {userSubscription?.data?.credits_purchased && (
              <NavItem role="presentation">
                <NavLink
                  to="#"
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    tabChange("2");
                  }}
                  type="button"
                >
                  User Subscription
                </NavLink>
              </NavItem>
            )}
            {userSubscription?.api && (
              <NavItem role="presentation">
                <NavLink
                  to="#"
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    tabChange("3");
                  }}
                  type="button"
                >
                  API Subscription
                </NavLink>
              </NavItem>
            )}
          </Nav>

          <CardBody className="p-4">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div>
                  <h5 className="fs-18 fw-bold">My Account Information</h5>
                  <Row>
                    <Col lg={12}>
                      <div className="mt-3 mb-3">
                        <label htmlFor="firstName" className="form-label">
                          User Name
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          id="firstName"
                          disabled
                          value={user?.name}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mt-3 mb-3">
                        <label htmlFor="firstName" className="form-label">
                          Email
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          id="firstName"
                          disabled
                          value={user?.email}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mt-3 mb-3">
                        <label htmlFor="firstName" className="form-label">
                          Customer ID
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          id="firstName"
                          disabled
                          value={userInfo?.data?.stripe_customerid}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mt-3 mb-3">
                        <label htmlFor="firstName" className="form-label">
                          Account Creation Date
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          id="firstName"
                          disabled
                          value={userInfo?.data?.created ?
                            new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(userInfo?.data?.created))
                            : ""}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mt-3 mb-3">
                        <label htmlFor="firstName" className="form-label">
                          Last Log In
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          id="firstName"
                          disabled
                          value={user?.updated_at ?
                            new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(user.updated_at))
                            : ""}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <hr></hr>
                <div>
                  <h5 className="fs-18 fw-bold"> Action </h5>
                  <div className="mt-4">
                    <button type="button" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} className="btn btn-purple mb-2">
                      Log Out
                    </button>
                    <p className="text-muted"> You may log out of your account and sign in back with the same account username and password.</p>
                  </div>
                  <div className="mt-4">
                    <DeleteButton />
                    <p className="text-muted"> Beware! this action cannot be reversed! do with your own risk.</p>
                  </div>
                </div>

              </TabPane>
              {userSubscription && (<TabPane tabId="2">
                <div>
                  <h5 className="fs-18 fw-bold"> My User Subscription Information</h5>
                  <Row>
                    <Col lg={12}>
                      <div className="mt-3 mb-3">
                        <label htmlFor="firstName" className="form-label">
                          Subscription Type
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          id="firstName"
                          disabled
                          value={userSubscription?.tierName}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mt-3 mb-3">
                        <label htmlFor="firstName" className="form-label">
                          Total Given Search Credits (This Month)
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          id="firstName"
                          disabled
                          value={userSubscription?.data?.credits_purchased}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mt-3 mb-3">
                        <label htmlFor="firstName" className="form-label">
                          Available Credits (This Month)
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          id="firstName"
                          disabled
                          value={userCredit}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mt-3 mb-3">
                        <label htmlFor="firstName" className="form-label">
                          Used Credits (This Month)
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          id="firstName"
                          disabled
                          value={userCreditUsed}
                        />
                      </div>
                    </Col>

                    <hr></hr>
                    <h5 className="fs-18 fw-bold">  Billing Information </h5>

                    <Col lg={12}>
                      <div className="mt-3 mb-3">
                        <label htmlFor="firstName" className="form-label">
                          Next Billing Date
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          id="firstName"
                          disabled
                          value={userSubscription?.data?.current_period_end ? new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }).format(new Date(userSubscription?.data?.current_period_end)) : ''}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mt-3 mb-3">
                        <label htmlFor="firstName" className="form-label">
                          Next Billing Amount
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          id="firstName"
                          disabled
                          value={`$${(userSubscription?.invoice?.total / 100).toFixed(2)}`}
                        />
                      </div>
                    </Col>
                    {userSubscription?.data?.tier === "2" && (
                      <>
                        <hr></hr>
                        <h5 className="fs-18 fw-bold">  Sub User Management </h5>
                        <Col lg={12}>
                          <div className="mt-3 mb-3">
                            <SubUserManagement />
                          </div>
                        </Col>
                      </>
                    )}
                    {!isSubUser && (
                      <>
                        <div className="mt-4 mb-4">
                          <h6> Download Invoice </h6>
                          <p className="text-muted"> You can view your latest invoice paid and download.</p>
                          <button
                            type="button"
                            className="btn btn-purple mb-2"
                            onClick={() => handleInvoice(userSubscription?.data?.invoice_id)}
                          >
                            View Latest Invoice
                          </button>
                        </div>
                        <hr></hr>
                        <div className="mt-0">
                          <h5 className="fs-18 fw-bold">Action</h5>
                          <div className="mt-4">
                            <button type="button" className="btn btn-purple mb-2" onClick={() => handleManageSubscription(userSubscription?.data?.customer_id)}>
                              Manage Subscription
                            </button>
                            <p className="text-muted">  You can create or update your subscription here. Changes can be made anytime during the cycle period.The Changes will be effective from the end of the current billing cycle.</p>
                          </div>
                          {/* <div className="mt-4">
                            <button type="button" className="btn btn-danger mb-2" onClick={() => cancelSubscription(userSubscription?.data?.sub_id)} disabled={userSubscription?.invoice === null}>
                              {userSubscription?.invoice === null ? 'Subscription Terminated' : 'Terminate Subscription'}
                            </button>
                            <p className="text-muted"> You can terminate your subscription here, but it will be effectievly only at the end of the monhtly subscription cycle. By terminating, you will not be charged after the end of this cycle date.</p>
                          </div> */}
                        </div>
                      </>
                    )}
                  </Row>
                </div>
              </TabPane>)}
              {userSubscription?.api && (
                <TabPane tabId="3">
                  <div>
                    <h5 className="fs-18 fw-bold"> My API Subscription Information</h5>
                    <Row>
                      <Col lg={12}>
                        <div className="mt-3 mb-3">
                          <label htmlFor="token" className="form-label">
                            Token
                          </label>
                          <div className="d-flex align-items-center">
                            <Input
                              type="text"
                              className="form-control"
                              id="token"
                              disabled
                              value={userSubscription?.api?.apiData.token}
                            />
                            <span className="ms-2" onClick={handleCopy} style={{ cursor: 'pointer' }}>
                              <i className="uil uil-copy"></i>
                            </span>
                            {copied && <span className="ms-2 text-success">Copied!</span>}
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="mt-3 mb-3">
                          <label htmlFor="firstName" className="form-label">
                            Subscription Type
                          </label>
                          <Input
                            type="text"
                            className="form-control"
                            id="firstName"
                            disabled
                            value={userSubscription?.api?.tierName !== null ? userSubscription?.api?.tierName : 'Free'}
                          />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="mt-3 mb-3">
                          <label htmlFor="firstName" className="form-label">
                            Total Given Search Credits (This Month)
                          </label>
                          <Input
                            type="text"
                            className="form-control"
                            id="firstName"
                            disabled
                            value={userSubscription?.api?.data?.credits_purchased}
                          />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="mt-3 mb-3">
                          <label htmlFor="firstName" className="form-label">
                            Available Credits (This Month)
                          </label>
                          <Input
                            type="text"
                            className="form-control"
                            id="firstName"
                            disabled
                            value={userSubscription.api.apiData.monthly_quota - userSubscription.api.apiData.monthly_usage}
                          />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="mt-3 mb-3">
                          <label htmlFor="firstName" className="form-label">
                            Used Credits (This Month)
                          </label>
                          <Input
                            type="text"
                            className="form-control"
                            id="firstName"
                            disabled
                            value={userSubscription.api.apiData.monthly_usage}
                          />
                        </div>
                      </Col>

                      <hr></hr>
                      <h5 className="fs-18 fw-bold">  Billing Information </h5>
                      <Col lg={12}>
                        <div className="mt-3 mb-3">
                          <label htmlFor="firstName" className="form-label">
                            Next Billing Date
                          </label>
                          <Input
                            type="text"
                            className="form-control"
                            id="firstName"
                            disabled
                            value={userSubscription?.api?.data?.current_period_end ? new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }).format(new Date(userSubscription?.api?.data?.current_period_end)) : ''}
                          />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="mt-3 mb-3">
                          <label htmlFor="firstName" className="form-label">
                            Next Billing Amount
                          </label>
                          <Input
                            type="text"
                            className="form-control"
                            id="firstName"
                            disabled
                            value={`$${(userSubscription?.api?.invoice?.total / 100).toFixed(2)}`}
                          />
                        </div>
                      </Col>
                      <div className="mt-4 mb-4">
                        <h6> Download Invoice </h6>
                        <p className="text-muted"> You can view your latest invoice paid and download.</p>
                        <button
                          type="button"
                          className="btn btn-purple mb-2"
                          onClick={() => handleInvoice(userSubscription?.api?.data?.invoice_id)}
                        >
                          View Latest Invoice
                        </button>
                      </div>
                      <hr></hr>
                      <div className="mt-0">
                        <h5 className="fs-18 fw-bold">Action</h5>
                        <div className="mt-4">
                          <button type="button" className="btn btn-purple mb-2" onClick={() => handleManageSubscription(userSubscription?.api?.data?.customer_id, true)}>
                            Manage Subscription
                          </button>
                          <p className="text-muted">  You can create or update your subscription here. Changes can be made anytime during the cycle period.The Changes will be effective from the end of the current billing cycle.</p>
                        </div>
                        {/* <div className="mt-4">
                          <button type="button" className="btn btn-danger mb-2" onClick={() => cancelSubscription(userSubscription?.api?.data?.sub_id)} disabled={userSubscription?.api?.invoice === null}>
                            {userSubscription?.api?.invoice === null ? 'Subscription Terminated' : 'Terminate Subscription'}
                          </button>
                          <p className="text-muted"> You can terminate your subscription here, but it will be effectievly only at the end of the monhtly subscription cycle. By terminating, you will not be charged after the end of this cycle date.</p>
                        </div> */}
                      </div>
                    </Row>
                  </div>
                  <hr></hr>


                </TabPane>
              )}
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RightSideContent;
