import React, { useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useAuth } from "Utils/AuthContext";
import getStripe from "Utils/getStripe";

const PricingPage = () => {
  const {
    user,
    isAuthenticated,
    logout,
    isAuthLoading,
    handleLogin,
    userCredit,
    isFetchingCredit,
    userSubscription,
    getAccessTokenSilently,
  } = useAuth();

  const handleCheckout = async (priceId) => {
    if (!isAuthenticated) {
      handleLogin();
      return;
    }
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: priceId,
          quantity: 1,
        },
      ],
      mode: 'subscription',
      successUrl: `https://smart-hscodes.com/`,
      cancelUrl: `https://smart-hscodes.com/`,
      customerEmail: user?.email,
    });
    if (error) {
      console.warn(error.message);
    }
  };

  const pricingpage = [
    {
      price_id: "null",
      id: "0",
      pricingIcon: "uim-telegram-alt",
      pricingName: "Trial",
      pricingPrice: "Free",
      pricingsuccessclass: "btn-soft-primary",
      pricingDetails: [
        {
          id: 1,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "100 Credits/month "
        },
        {
          id: 1,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "HS Code Match "
        },
        {
          id: 2,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "Regulations API "
        },
        {
          id: 5,
          pricingInnerClassName: "pricing-item text-decoration-line-through",
          pricingInnerIcon:
            "mdi mdi-close-thick bg-muted-subtle text-muted me-2",
          pricingInnerText: "Credits Top Up"
        },
        {
          id: 5,
          pricingInnerClassName: "pricing-item text-decoration-line-through",
          pricingInnerIcon:
            "mdi mdi-close-thick bg-muted-subtle text-muted me-2",
          pricingInnerText: "Consolidate APIs"
        },
        {
          id: 6,
          pricingInnerClassName: "pricing-item text-decoration-line-through",
          pricingInnerIcon:
            "mdi mdi-close-thick bg-muted-subtle text-muted me-2",
          pricingInnerText: "Technical Support"
        }
      ]
    },
    {
      id: "10",
      price_id: "price_1QA41M2LftaZnN8oUaCPS5oq",
      pricingIcon: "fluent-premium-12-filled",
      pricingName: "Premium",
      pricingPrice: "$250",
      pricingsuccessclass: "btn-primary",
      pricingDetails: [
        {
          id: 1,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "10,000 Credits/month "
        },
        {
          id: 1,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "HS Code Match "
        },
        {
          id: 2,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "Regulations API "
        },
        {
          id: 5,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
          "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "Credits Top Up"
        },
        {
          id: 5,
          pricingInnerClassName: "pricing-item text-decoration-line-through",
          pricingInnerIcon:
            "mdi mdi-close-thick bg-muted-subtle text-muted me-2",
          pricingInnerText: "Consolidate APIs"
        },
        {
          id: 6,
          pricingInnerClassName: "pricing-item text-decoration-line-through",
          pricingInnerIcon:
            "mdi mdi-close-thick bg-muted-subtle text-muted me-2",
          pricingInnerText: "Technical Support"
        }
      ]
    },
    {
      price_id: "custom",
      id: "2",
      pricingIcon: "mdi-company",
      pricingName: "Company",
      pricingPrice: "Custom",
      pricingsuccessclass: "btn-soft-primary",
      pricingDetails: [
        {
          id: 1,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "Unlimited Credits "
        },
        {
          id: 1,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "HS Code Match "
        },
        {
          id: 2,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
            "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "Regulations API "
        },
        {
          id: 5,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
          "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "Credits Top Up"
        },
        {
          id: 5,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
          "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "Consolidate APIs"
        },
        {
          id: 6,
          pricingInnerClassName: "pricing-item",
          pricingInnerIcon:
          "mdi mdi-check-bold bg-success-subtle text-success me-2",
          pricingInnerText: "Technical Support"
        }
      ]
    },
  ];
  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center">
                {/* <span className="badge bg-warning-subtle text-warning  fs-15 mb-2">
                  Choose Your Plan
                </span> */}
                <h3> API Pricing </h3>
                <p className="text-muted">
                   For developers and companies looking for simple integration using our backend APIs. 
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            {pricingpage.map((plan, key) => (
              <Col lg={4} md={6} className="mt-5 pt-2" key={key}>
                <Card className="pricing-box bg-light">
                  <CardBody className="p-4 px-lg-5">
                    <div className="pricing-icon bg-light rounded-circle icons-md">
                      <Icon
                        icon={plan.pricingIcon}
                        className="text-primary"
                      />
                    </div>
                    <div className="pricing-name text-center mt-4 pt-2">
                      <h4 className="fs-18">
                        {plan.pricingName}
                      </h4>
                    </div>
                    <div className="pricing-price text-center mt-4">
                      <h2 className="fw-semibold">
                        {plan.pricingPrice}
                        <small className="fs-16">/mo</small>
                      </h2>
                    </div>
                    <ul className="list-unstyled pricing-details text-muted mt-4">
                      {(plan.pricingDetails || []).map(
                        (pricingpageInnerDetails, key) => (
                          <li
                            key={key}
                            className={
                              pricingpageInnerDetails.pricingInnerClassName
                            }
                          >
                            <i
                              className={
                                pricingpageInnerDetails.pricingInnerIcon
                              }
                            ></i>{" "}
                            {pricingpageInnerDetails.pricingInnerText}
                          </li>
                        )
                      )}
                    </ul>
                    <div className="text-center mt-4 mb-2">
                    {isFetchingCredit ? (
                        <Button className={`btn ${plan.pricingsuccessclass} rounded-pill`} disabled>
                          <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                          Loading...
                        </Button>
                      ) : (
                        plan.price_id === "custom" ? (
                          <Link to={"/contact"} className={`btn ${plan.pricingsuccessclass} rounded-pill`}>Contact Us</Link>
                        ) : plan.price_id === "null" ? (
                          <Link className={`btn ${plan.pricingsuccessclass} rounded-pill`} to={"/api-doc"}>Free Plan</Link>
                        ) : !userSubscription?.api?.data ? (
                          <Button onClick={() => handleCheckout(plan.price_id)} className={`btn ${plan.pricingsuccessclass} rounded-pill`}>Subscribe <i className="uil uil-arrow-right"></i></Button>
                        ) : plan.id === userSubscription?.api?.data.tier ? (
                          <Button className={`btn ${plan.pricingsuccessclass} rounded-pill`} disabled>Already Subscribed</Button>
                        ) : (
                          <Link to={"/contact"} className={`btn ${plan.pricingsuccessclass} rounded-pill`}>Contact Us</Link>
                        )
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="section bg-light">
        <Container>
          <Row className="justify-content-center">
            <div className="section-title text-center">
              <h3 className="title mb-2 pb-2">
                 Global HS Codes and Regulations.
              </h3>
              <p className="para-desc text-muted mx-auto">
                 Get an instant match using our proprietary database and matching algorithm.
              </p>
              <div className="mt-4">
                <Link to="/contact" className="btn btn-primary btn-hover mt-2">
                  <i className="uil uil-phone me-1"></i> Contact
                </Link>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default PricingPage;
