import CryptoJS from "crypto-js";

function decrypt(text) {
    const secretKey = "6f8f57715090da2632453988d9c54c66e4a0494643ccecd1a10bd2b64aaf37f4";
    const textParts = text.split(":");
    const iv = CryptoJS.enc.Hex.parse(textParts.shift());
    const encryptedText = textParts.join(":");
    const encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedText);
    const encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  
    const decrypted = CryptoJS.AES.decrypt(
      encryptedBase64Str,
      CryptoJS.enc.Hex.parse(secretKey),
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
  
    return decrypted.toString(CryptoJS.enc.Utf8);
  }


export default decrypt