import { getConfig } from "config";
import decrypt from "./decrypt";
const config = getConfig();

function cacheData(url, data) {
  const cacheItem = {
    data,
    date: new Date().toISOString().split('T')[0],
  };

  try {
    localStorage.setItem(url, JSON.stringify(cacheItem));
  } catch (error) {
    if (error.name === 'QuotaExceededError') {
      console.warn('LocalStorage is full. Clearing storage and retrying...');
      localStorage.clear();
    } else {
      console.error('Failed to cache data:', error);
    }
  }
}

function getCachedData(url) {
  const cachedItem = localStorage.getItem(url);
  if (!cachedItem) return null;

  const { data, date } = JSON.parse(cachedItem);
  const currentDate = new Date().toISOString().split('T')[0];

  if (date !== currentDate) {
    localStorage.removeItem(url);
    return null;
  }

  return data;
}

async function fetchEnc(url, options = {}) {
  let isEncrypted = config.encrypt_response;
  if (options.userEmail) {
    isEncrypted = false;
  }
  url = new URL(url);
  if (isEncrypted) {
    url.searchParams.set("encrypt", "true");
  } else {
    url.searchParams.set("encrypt", "false");
  }

  const shouldCache = config.cache_response;
  if (shouldCache) {
    const cachedData = getCachedData(url);
    if (cachedData) {
      return {
        json: async () => {
          return cachedData;
        },
      };
    }
  }

  let headers
  if (options.userEmail) {
    headers = {
      ...options.headers,
      "Accept": "application/json",
      "x-user-email": options.userEmail,
      "x-user-sub": options.userSub,
    };

    isEncrypted = false
  } else {
    headers = {
      ...options.headers,
      "Accept": isEncrypted ? "application/text" : "application/json",
      "x-user-email": isEncrypted ? "unregistered@smarths.com" : "no.reply.moaah@gmail.com",
      "x-user-sub": "free",
    };
  }

  const response = await fetch(url, { ...options, headers });
  const responseText = await response.text();

  if (isEncrypted) {
    const decryptedData = decrypt(responseText);
    const jsonData = JSON.parse(decryptedData);
    if (shouldCache) {
      cacheData(url, jsonData);
    }
    return {
      ...response,
      json: async () => jsonData,
    };
  }

  const jsonData = JSON.parse(responseText);
  if (shouldCache) {
    cacheData(url, jsonData);
  }
  return {
    ...response,
    json: async () => jsonData,
  };
}

export default fetchEnc;
